import { action, thunk } from 'easy-peasy';

import getReservations from '../../services/reservations/get';
import { postReservation, postCancelReservation } from '../../services/reservations/post';

import { sortByNumberItems } from '../../shared/utilities';

const reservationsModel = {
  items: {
    next7Days: null,
    last7Days: null,
    next30Days: null
  },
  loading: false,
  loadingCard: null,
  error: '',
  getReservations: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getReservations(actions, payload);
  }),
  storeGetReservations: action((state, payload) => {
    state.items[payload.type] = payload.data?.sort((a, b) => sortByNumberItems(a, b, 'dateEpoch', payload.type.includes('next') ? 'asc' : 'desc'));
  }),
  postReservation: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    await postReservation(actions, payload);
  }),
  postCancelReservation: thunk(async (actions, payload) => {
    actions.setLoadingCard(payload);
    actions.setError('');
    return await postCancelReservation(actions, payload);
  }),
  storePostCancelReservation: action((state, payload) => {
    Object.keys(state.items).forEach(item => {
      state.items[item] = state.items[item]?.filter(reservation => reservation.id !== payload.reservationId);
    });
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoadingCard: action((state, payload) => {
    state.loadingCard = payload ? payload.reservationId : payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default reservationsModel;
