import axios from 'axios';

import { today, next7Days, logSentryEndpointError } from '../../shared/utilities';

const getReservations = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations`,
  params: {
    startDate: payload?.startDate || today,
    endDate: payload?.endDate || next7Days,
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetReservations({ data: response.data, type: payload?.type || 'next7Days' });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default getReservations;
