import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import SwipeableViews from 'react-swipeable-views';

import CustomCard from '../components/CustomCard';
import LoadingCard from '../components/LoadingCard';

import { formatDate, formatTime, today, next7Days, last7Days, next30Days, handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `reservations-tabpanel-${ index }` }
      aria-labelledby={ `reservations-tab-${ index }` }
      { ...other }
    >
      { value === index && <Box p={ 3 }>{ children }</Box> }
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `reservations-tab-${ index }`,
    'aria-controls': `reservations-tabpanel-${ index }`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    '& .react-swipeable-view-container': {
      minHeight: 'calc(100vh - 104px)',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 112px)',
      },
    },
    '& .MuiTab-root': {
      fontSize: '12px',
      lineHeight: '16px',
      padding: 0,
    },
  },
}));

const Reservations = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [currentTab, setCurrentTab] = React.useState(0);

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const reservations = useStoreState(state => state.reservations.items);
  const reservationsLoading = useStoreState(state => state.reservations.loading);
  const reservationLoadingCard = useStoreState(state => state.reservations.loadingCard);
  // const reservationsError = useStoreState(state => state.reservations.error);
  const getReservations = useStoreActions(actions => actions.reservations.getReservations);
  const postCancelReservation = useStoreActions(actions => actions.reservations.postCancelReservation);
  const storeGetReservations = useStoreActions(actions => actions.reservations.storeGetReservations);

  const items = [
    { name: 'next7Days', startDate: today, endDate: next7Days },
    { name: 'last7Days', startDate: last7Days, endDate: today },
    { name: 'next30Days', startDate: today, endDate: next30Days },
  ];

  React.useEffect(() => {
    const { name, startDate, endDate } = items[currentTab];
    if ( !reservations[name] ) {
      getReservations({ type: name, startDate, endDate }).then(() => {
        const reservationsState = store.getState().reservations;
        if (!reservationsState.loading && !reservationsState.error) {
          // console.log(reservationsState.items);
        } else {
          handleEndpointErrors(reservationsState, props, setSnackbar, t);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  React.useEffect(() => {
    return () => {
      items.forEach(item => {
        storeGetReservations({ data: null, type: item.name });
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const onCancelHandler = (reservationId) => event => {
    postCancelReservation({
      reservationId,
      status: 'cancelled',
	    notes: 'Cancelled by passenger'
    }).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.loading && !reservationsState.error) {
        // console.log(reservationsState);
      } else {
        if (reservationsState.error.status === 460) {
          handleEndpointErrors(reservationsState, props, setSnackbar, t, 'cancel');
        } else {
          handleEndpointErrors(reservationsState, props, setSnackbar, t);
        }
      }
    });
  };

  const onDetailHandler = (id) => event => {
    props.history.push({ pathname: `${ props.match.path }/detail/${ id }` });
  };

  const t = useTranslation();

  storeMenuTitle(t('reservations.pageTitle'));

  return (
    <div className={ classes.root }>
      <AppBar color="inherit" position="static">
        <Tabs value={ currentTab } onChange={ handleChange } variant="fullWidth" aria-label={ t('reservations.aria') }>
          {
            items.map((item, index) => (
              <Tab key={ item.name } label={ t(`reservations.${ item.name }`) } { ...a11yProps(index) } />
            ))
          }
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
        index={ currentTab }
        onChangeIndex={ (index) => handleChange(null, index) }
      >
        {
          items.map((item, index) => (
            <TabPanel key={ item.name } value={ currentTab } index={ index } dir={ theme.direction }>
              { reservationsLoading ? <LoadingCard length={ 4 } height={ 207 } /> :
                reservations[item.name] && reservations[item.name].length ? reservations[item.name].map(reservation => (
                  reservationLoadingCard === reservation.id ?
                    <LoadingCard key={ reservation.id } length={ 1 } height={ 207 } /> :
                    <CustomCard
                      key={ reservation.id }
                      type={ item.name }
                      direction={ t(`global.direction.${ reservation.route.direction }`) }
                      status={ reservation.status }
                      route={{
                        name: reservation.route.name,
                        origin: { name: reservation.route.origin.description, time: formatTime(reservation.schedule.departureTime) },
                        destination: { name: reservation.route.destination.description, time: formatTime(reservation.schedule.arrivalTime) },
                      }}
                      date={ `${ formatDate(reservation.date, t) }` }
                      body={ `${ reservation?.route?.direction?.toLowerCase() === 'in' ? t('reservations.card.in') : t('reservations.card.out') }: ${ reservation.place.description }` }
                      body2={ `${ reservation.place.county.name }, ${ reservation.place.province.name }` }
                      body3={ reservation.place.arrivalTime ? `${ t('reservations.card.arrivalTime') }: ${ formatTime(reservation.place.arrivalTime) }` : null }
                      note={ reservation.notes }
                      tripInfo={ reservation.tripId ? {
                        title: t('reservations.card.tripInfo.title'),
                        status: reservation.tripStatus,
                        passenger: reservation.passenger.name,
                        driver: reservation.driver.name,
                        ticket: reservation.ticket,
                        plate: reservation.vehicle.license_plate,
                        id: reservation.tripId,
                      } : null }
                      primaryIconBtn={localStorage.getItem('clientRsvAllowDelete') === 'true' ? {
                        icon: <DeleteOutlineIcon color="primary" />,
                        handler: onCancelHandler(reservation.id),
                        backText: t('reservations.card.backCard.btnCancel')
                      } : undefined}
                      secondaryBtn={{
                        text: t('reservations.card.detail'),
                        handler: onDetailHandler(reservation.id),
                        backText: t('reservations.card.backCard.btnBack')
                      }}
                      backMsg={ t('reservations.card.backCard.msgCancel') }
                      backIcon={ <DeleteOutlineIcon /> }
                    />
                )) : (
                  <CustomCard type="notFound" notFoundMsg={ t('reservations.card.notFound') } />
                )
              }
            </TabPanel>
          ))
        }
      </SwipeableViews>
    </div>
  );
}

Reservations.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(Reservations);
